export const passwordErrorCodeDictionary = {
  invalid:
    'Invalid password. A password must contain at least 8 characters, 1 number and 1 uppercase letter.',

  // At least 8 characters long
  password_short: 'Must be at least 8 characters',

  // Missing at least one number
  no_number: 'Must contain at least one number',

  // Missing at least one uppercase letter
  no_uppercase: 'Must contain at least one uppercase letter',

  // Must not contain any common words
  no_common_words: 'Contains too many common words',

  // Must not contain any sequences of at least 3 characters/number
  no_sequences:
    'Must not contain any more than 3 sequential characters or numbers',

  // Must not contain any repeated consecutive characters/numbers (3/4)
  no_repetitions:
    'Must not contain any more then 3 repeated consecutive letters or numbers',

  // An overall weak password error
  weak_password: 'This password is commonly used',
}

export const emailErrorCodeDictionary = {
  invalid: 'Invalid email',
  taken: 'Invalid email',
}

export const accessCodeErrorCodeDictionary = {
  invalid: 'Invalid access code',
}

const strings = {
  password: passwordErrorCodeDictionary,
  email: emailErrorCodeDictionary,
  accessCode: accessCodeErrorCodeDictionary,

  doesnt_match: "Passwords don't match",
  invalid: 'Invalid field',
}

export default strings
