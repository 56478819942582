const { version } = require('../package.json')

module.exports = {
  API_SERVICE_URL: process.env.NEXT_PUBLIC_API_SERVICE_URL,
  SHARED_PROGRAM_API_URL: process.env.NEXT_PUBLIC_SHARED_PROGRAM_API_URL,
  BOA_PROGRAM_API_URL: process.env.NEXT_PUBLIC_BOA_PROGRAM_API_URL,
  APP_STAGE: process.env.NEXT_PUBLIC_APP_STAGE,
  APP_VERSION: version,
  BUGSNAG_KEY: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
  BOA_TENANT_ID: process.env.NEXT_PUBLIC_BOA_TENANT_ID,
  STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  ORIENTATION_CALENDLY_URL: process.env.NEXT_PUBLIC_ORIENTATION_CALENDLY_URL,
}
