import { createStore, applyMiddleware, compose } from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import { debounce } from 'lodash'
import thunk from 'redux-thunk'
import reducer from './reducer'
import api from '../apis/base'

let composeEnhancers = compose

if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsBlacklist: [],
  })
}

const enhancer = composeEnhancers(
  applyMiddleware(thunk.withExtraArgument({ api })),
  batchedSubscribe(debounce((notify) => notify())),
)

const defaultState = {
  currentUser: {
    profileForm: {
      firstName: '',
      lastName: '',
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
      },
      // optional insurance form
      insuranceProvider: {
        name: '',
        primaryInsurer: '',
        idNumber: '',
        groupIdNumber: '',
      },
      stripeTokenId: '',
    },
  },
}

export default createStore(reducer, defaultState, enhancer)
