/* eslint-disable */

import '../lib/datadog'
import ErrorBoundary from '@/components/error-boundary'
import store from '@/lib/redux/store'
import { Provider } from 'react-redux'

export default function App({ Component, pageProps }) {
  require('../styles/new-custom-bootstrap.scss')
  require('../styles/new-globals.scss')

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <script src="https://cdn.hellosign.com/public/js/embedded/v2.10.0/embedded.development.js"></script>
        <Component {...pageProps} />
      </Provider>
    </ErrorBoundary>
  )
}
