import axios from 'axios'
import Promise from 'bluebird'
import camelize from 'camelize'
import snakeize from 'snakeize'
import { authorizationHeaders } from '../functions'

function toCamelCase(data) {
  const acronyms = { id: 'ID', url: 'URL' }
  return camelize(data, { acronyms })
}

function buildAPIClient(baseURL) {
  const client = axios.create({
    baseURL,
    withCredentials: true,
  })

  client.interceptors.request.use(
    function (config) {
      config.data = snakeize(config.data)
      config.headers = { ...authorizationHeaders(), ...config.headers }

      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )

  client.interceptors.response.use(
    function (response) {
      response.data = toCamelCase(response.data)
      return response
    },
    function (error) {
      if (!error.response) {
        return error
      }

      console.error(error)

      error.response.data = toCamelCase(error.response.data)
      return Promise.reject(error)
    },
  )

  return client
}

export default buildAPIClient
