if (window.MSInputMethodContext && document.documentMode) {
  console.log('Loading Polyfills for IE')
  require('react-app-polyfill/ie11')
  require('react-app-polyfill/stable')
  require('bootstrap-ie11/css/bootstrap-ie11.min.css')
  require('@webcomponents/webcomponentsjs')
}

if (!Math.trunc) {
  Math.trunc = function (v) {
    return v < 0 ? Math.ceil(v) : Math.floor(v)
  }
}

if (!global.CustomEvent) {
  require.ensure(['custom-event-polyfill'], function (require) {
    require('custom-event-polyfill')
  })
}

if (!global.Intl) {
  require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], function (require) {
    require('intl')
    require('intl/locale-data/jsonp/en.js')
  })
}
