import { CURRENT_USER_ID } from '../constants'
import { storeKey } from '../functions'

export const AUTHENTICATE = 'AUTHENTICATE'

export default function reducer(state = null, { type, payload }) {
  switch (type) {
    case AUTHENTICATE:
      return {
        ...state,
        user: { ...payload.user },
      }

    default:
      return state
  }
}

export function authenticate(user) {
  return {
    type: AUTHENTICATE,
    payload: { user },
  }
}

export function logoutUser() {
  return function (dispatch, getstate, { api }) {
    return api.logoutUser().then(function () {
      dispatch(authenticate(null))
    })
  }
}

export function loginUser(values) {
  return function (dispatch, getstate, { api }) {
    return api.loginUser(values)
  }
}

export function verifyCurrentUser() {
  return function (dispatch, getstate, { api }) {
    return api.verifyCurrentUser().then((user) => {
      storeKey(CURRENT_USER_ID, user.id)
      return user
    })
  }
}

export function resetPassword(email) {
  return function (dispatch, getstate, { api }) {
    return api.resetPassword(email)
  }
}

export function updatePassword(params) {
  return function (dispatch, getState, { api }) {
    return api.updatePassword(params).then(function (response) {
      return response
    })
  }
}
