import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

if (process.env.NEXT_PUBLIC_DD_SERVICE) {
  const applicationId = 'f605f316-8440-4219-9dfb-8d14d715d825'
  const clientToken = 'pub509a7255b76f70e952e4c41b890fc8c8'
  const site = 'datadoghq.com'

  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: site,
    service: process.env.NEXT_PUBLIC_DD_SERVICE,
    env: process.env.NEXT_PUBLIC_DD_ENV,
    version: process.env.NEXT_PUBLIC_DD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogLogs.init({
    clientToken: clientToken,
    site: site,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })
}
